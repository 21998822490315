import React from "react";
import NumberSvg from "./number-svg";

type IconProps = {
  className?: string;
  color?: string;
};
const Number8Svg = ({ className, color }: IconProps) => (
  <NumberSvg
    {...{ color, className }}
    getPath={(id) => (
      <path
        transform="translate(-206 -834)"
        fill={`url(#${id})`}
        fillRule="evenodd"
        d="M271 834c35.899 0 65 29.101 65 65s-29.101 65-65 65-65-29.101-65-65 29.101-65 65-65zm4.949 29.614c-2.876 0-5.533.447-7.97 1.34-2.437.893-4.548 2.111-6.334 3.655-1.787 1.544-3.187 3.346-4.2 5.404-1.015 2.059-1.522 4.269-1.522 6.63 0 1.514.227 2.967.681 4.36a19.07 19.07 0 0 0 1.84 3.996 31.736 31.736 0 0 0 2.656 3.723 57.85 57.85 0 0 0 3.178 3.542c-3.088 1.362-5.744 2.785-7.97 4.269-2.224 1.483-4.056 3.065-5.494 4.745-1.438 1.68-2.497 3.482-3.179 5.404-.68 1.922-1.021 3.973-1.021 6.153.03 2.694.583 5.131 1.657 7.311a16.86 16.86 0 0 0 4.405 5.585c1.862 1.544 4.072 2.733 6.63 3.565 2.558.833 5.366 1.249 8.423 1.249 3.421-.06 6.6-.666 9.537-1.816 2.936-1.15 5.48-2.702 7.629-4.655a21.564 21.564 0 0 0 5.063-6.834 19.163 19.163 0 0 0 1.839-8.265c-.06-2.15-.44-4.132-1.135-5.949a23.33 23.33 0 0 0-2.725-5.108 34.278 34.278 0 0 0-3.792-4.496 136.742 136.742 0 0 0-4.291-4.11c4.632-2.542 8.068-5.199 10.308-7.969 2.24-2.77 3.36-5.714 3.36-8.832-.06-1.938-.537-3.694-1.43-5.268-.893-1.574-2.12-2.929-3.678-4.064-1.56-1.135-3.406-2.013-5.54-2.634-2.134-.62-4.443-.93-6.925-.93zm-7.856 36.42a123.863 123.863 0 0 1 3.678 3.836 33.789 33.789 0 0 1 3.133 3.951 21.284 21.284 0 0 1 2.203 4.178c.56 1.438.855 2.944.885 4.518 0 1.574-.257 3.065-.772 4.473-.514 1.408-1.226 2.641-2.134 3.7-.908 1.06-1.99 1.9-3.247 2.521-1.256.62-2.626.931-4.11.931-1.301 0-2.49-.265-3.564-.795-1.075-.53-2.006-1.294-2.793-2.293-.787-.999-1.4-2.202-1.839-3.61-.439-1.408-.658-2.974-.658-4.7 0-3.36.794-6.478 2.384-9.354 1.589-2.876 3.867-5.328 6.834-7.357zm9.263-31.97c1.908 0 3.414.621 4.519 1.862s1.657 2.952 1.657 5.132c0 1.21-.174 2.46-.522 3.746a18.913 18.913 0 0 1-1.521 3.837 24.184 24.184 0 0 1-5.54 6.993 42.161 42.161 0 0 1-2.68-2.838 23.82 23.82 0 0 1-2.156-2.929 14.778 14.778 0 0 1-1.476-3.133 10.699 10.699 0 0 1-.545-3.406c0-1.332.204-2.566.613-3.7.409-1.136.976-2.112 1.703-2.93a7.878 7.878 0 0 1 2.61-1.93c1.015-.469 2.128-.704 3.338-.704z"
      />
    )}
  />
);

export default Number8Svg;
