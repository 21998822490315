import React, { useState } from "react";
import styled from "styled-components";

import Card, { PaddingType } from "../card";
import Poster from "../poster";
import Toggle from "../controls/toggle";
import WrappedItems from "../controls/wrapped-items";
import Carousel from "../silo-components/carousel";

import { PosterContentType } from "../poster/poster-content";

const defaultContributorTypes = [
  { value: "actors", display: "Actors" },
  { value: "directors", display: "Directors" },
];

const TogglesContainer = styled.div`
  margin-top: ${(props) => props.theme.Padding * 2}px;
`;

type StatsContributorSiloProps = {
  userProfile?: any;
  contributorTypes?: any;
  width?: number;
  getDescriptionFn?: any;
};
const StatsContributorSilo = ({
  userProfile,
  contributorTypes = defaultContributorTypes,
  width = 66,
  getDescriptionFn,
}: StatsContributorSiloProps) => {
  const [currentContributorType, setCurrentContributorType] = useState(
    contributorTypes[0]
  );

  return (
    <Card
      title={`Top ${currentContributorType.display}`}
      width={width}
      padding={PaddingType.VERTICAL}
    >
      {userProfile && (
        <>
          <Card.SiloPadding>
            <Carousel sizingConfig={Poster.SizingConfig} inContainer>
              {userProfile[currentContributorType.value].map((item, i) => {
                // Use the default description if not description function is passed down
                const getDescription = getDescriptionFn
                  ? () => getDescriptionFn({ item, currentContributorType })
                  : null;
                //remove the mhric- from the beginning of the alt-id
                return (
                  <Poster
                    key={`${item.mhid}-${i}`}
                    item={item}
                    contentIndex={i}
                    posterContent={({ isHovered }) =>
                      isHovered ? PosterContentType.STATS : null
                    }
                    href={`/spotlight/person/${item.altId.slice(6)}`}
                    {...{ getDescription }}
                  />
                );
              })}
            </Carousel>
          </Card.SiloPadding>
          <TogglesContainer>
            <WrappedItems>
              {contributorTypes.map((option) => (
                <Toggle
                  onClick={() => setCurrentContributorType(option)}
                  isSelected={currentContributorType.value === option.value}
                  key={option.value}
                  name={option.display}
                />
              ))}
            </WrappedItems>
          </TogglesContainer>
        </>
      )}
    </Card>
  );
};

export default StatsContributorSilo;
