import React, { useState } from "react";
import styled from "styled-components";
import Tooltip, { TooltipPosition } from "../controls/tooltip";

import { useSpring, animated } from "react-spring";

import useTheme from "../../hooks/use-theme";
import { useResponsive } from "../responsive-provider";

const Container = styled.div`
  display: inline-block;
`;

type ButtonProps = {
  $hasArrowDown?: boolean;
  $isSelected?: boolean;
};
const AnimatedButton = styled(animated.button)<ButtonProps>`
  ${(props) => props.theme.AppearanceNone()}
  border-radius: ${(props) => props.theme.BorderRadiusSmall}px;
  cursor: pointer;
  opacity: 1;
  outline: none;
  padding: ${(props) => props.theme.Padding}px;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.$isSelected &&
    props.$hasArrowDown &&
    `
  &::after {
    transform: translateX(-50%);
    content: "";
    border-style: solid;
    border-color: ${props.theme.Color.ButtonHover} transparent transparent;
    border-image: initial;
    border-width: 10px 10px 0px;
    height: 0px;
    width: 0px;
    position: absolute;
    bottom: -9px;
    left: 50%;
  }

  `}
`;

const ICON_SIZE = 30;
const ButtonIcon = styled.div`
  width: ${ICON_SIZE - 6}px;
  height: ${ICON_SIZE - 6}px;
`;

type ButtonTextProps = {
  $hasIcon?: boolean;
};
const ButtonText = styled.div<ButtonTextProps>`
  ${(props) => props.theme.FontHeader()}
  font-size: 16px;
  margin-left: ${(props) => (props.$hasIcon ? props.theme.Padding / 2 : 0)}px;
`;

// TODO: This file shares a lot of behavior with source-filters. Can we combine that into a single utility?
type ToggleProps = {
  Icon?: any;
  name?: string;
  description?: string;
  onClick?: any;
  isSelected?: boolean;
  hasArrowDown?: boolean;
  width?: number;
};

const Toggle = ({
  Icon,
  name,
  description,
  onClick,
  isSelected,
  hasArrowDown,
  width,
}: ToggleProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const theme = useTheme();
  const { isTouchDevice } = useResponsive();

  const { backgroundColor, color, scale } = useSpring({
    color: isSelected ? theme.Color.SearchColor : theme.Color.PrimaryColor,
    backgroundColor: isSelected
      ? theme.Color.Yellow
      : theme.Color.TransparentLight,
    scale: isHovered ? 1.1 : 1,
    config: { mass: 1, tension: 150, friction: 14 },
  });

  const button = (
    <AnimatedButton
      {...{ onClick }}
      $hasArrowDown={hasArrowDown}
      $isSelected={isSelected}
      style={
        {
          backgroundColor: backgroundColor.to((c) => `${c}`),
          color: color.to((c) => `${c}`),
          transform: scale.to((s) => `scale(${s})`),
          width: width ? `${width}px` : "auto",
        } as any
      }
    >
      {Icon && (
        <ButtonIcon>
          <Icon />
        </ButtonIcon>
      )}
      <ButtonText $hasIcon={!!Icon}>{name}</ButtonText>
    </AnimatedButton>
  );

  const finalButton = !description ? (
    button
  ) : (
    <Tooltip text={description} position={TooltipPosition.BottomCenter}>
      {button}
    </Tooltip>
  );

  return (
    <Container
      onMouseEnter={!isTouchDevice ? () => setIsHovered(true) : null}
      onMouseLeave={!isTouchDevice ? () => setIsHovered(false) : null}
    >
      {finalButton}
    </Container>
  );
};
export default Toggle;
