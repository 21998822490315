import React from "react";
import styled from "styled-components";
import {
  XAxis,
  YAxis,
  FlexibleXYPlot,
  VerticalBarSeries,
  HorizontalGridLines,
} from "react-vis";

import useTheme from "../../hooks/use-theme";
import useWindowSize from "../../hooks/use-window-size";

import ChartLegend from "./chart-legend";

// Without this extra div, the year chart will not vertically center within the card
const Container = styled.div``;

const YearChart = ({ DEFAULT_COLOR, VARIABLE_COLOR, userProfile }) => {
  const theme = useTheme();
  const windowSize = useWindowSize();
  const { releaseYear } = userProfile;

  const getReleaseYearData = (contentType) =>
    releaseYear.map(({ releaseYear, count }) => {
      const decadeFormat =
        windowSize.width > 1090 ? releaseYear : `'${releaseYear.substr(-2)}`;

      return {
        x: decadeFormat,
        y: count[contentType],
      };
    });

  const mediaTypeData = [
    {
      title: "Movies",
      color: DEFAULT_COLOR,
    },
    {
      title: "TV Series",
      color: VARIABLE_COLOR,
    },
  ];

  return (
    <Container>
      <FlexibleXYPlot
        {...{
          xType: "ordinal",
          yType: "linear",
        }}
        height={200}
        stackBy="y"
      >
        <HorizontalGridLines style={{ stroke: theme.Color.TransparentDark }} />
        <XAxis
          style={{
            fontFamily: '"Muli", sans-serif',
            fontSize: 12,
            textAnchor: "middle",
            fill: theme.Color.TransparentDark,
          }}
        />
        <YAxis
          style={{
            fontFamily: '"Muli", sans-serif',
            fontSize: 12,
            fill: theme.Color.TransparentDark,
          }}
        />
        <VerticalBarSeries
          data={getReleaseYearData("movie")}
          barWidth={0.5}
          color={DEFAULT_COLOR}
        />
        <VerticalBarSeries
          data={getReleaseYearData("tv")}
          barWidth={0.5}
          color={VARIABLE_COLOR}
        />
      </FlexibleXYPlot>

      <ChartLegend items={mediaTypeData} />
    </Container>
  );
};

export default YearChart;
