import React from "react";
import NumberSvg from "./number-svg";

type IconProps = {
  className?: string;
  color?: string;
};
const Number1Svg = ({ className, color }: IconProps) => (
  <NumberSvg
    {...{ color, className }}
    getPath={(id) => (
      <path
        transform="translate(-47)"
        fill={`url(#${id})`}
        fillRule="evenodd"
        d="M112 130c-35.899 0-65-29.101-65-65S76.101 0 112 0s65 29.101 65 65-29.101 65-65 65zm-14.286-30h13.234l14.623-69.286c-2.78 1.689-5.323 3.008-7.626 3.958-2.302.95-4.41 1.673-6.325 2.17-1.914.498-3.648.807-5.203.927-1.555.121-2.96.181-4.217.181l-1.076 4.252h8.702L97.714 100z"
      />
    )}
  />
);

export default Number1Svg;
