import React from "react";
import NumberSvg from "./number-svg";

type IconProps = {
  className?: string;
  color?: string;
};
const Number10Svg = ({ className, color }: IconProps) => (
  <NumberSvg
    {...{ color, className }}
    getPath={(id) => (
      <path
        transform="translate(-604 -811)"
        fill={`url(#${id})`}
        fillRule="evenodd"
        d="M669 811c35.899 0 65 29.101 65 65s-29.101 65-65 65-65-29.101-65-65 29.101-65 65-65zm23.955 29.75c-3.149 0-6.115.59-8.9 1.771-2.786 1.181-5.351 2.831-7.698 4.95-2.346 2.12-4.45 4.655-6.312 7.606-1.861 2.952-3.443 6.206-4.745 9.764-1.302 3.557-2.293 7.356-2.974 11.398a74.885 74.885 0 0 0-1.022 12.465c0 3.239.295 6.274.885 9.104.59 2.83 1.597 5.29 3.02 7.38 1.423 2.088 3.345 3.738 5.767 4.95 2.422 1.21 5.45 1.816 9.082 1.816 2.846 0 5.472-.454 7.879-1.363a24.341 24.341 0 0 0 6.584-3.723c1.983-1.575 3.762-3.436 5.336-5.586a45.452 45.452 0 0 0 4.178-6.948 57.113 57.113 0 0 0 3.11-7.81 77.813 77.813 0 0 0 2.112-8.151c.545-2.71.946-5.359 1.203-7.947.258-2.588.386-5.018.386-7.288 0-3.36-.355-6.418-1.067-9.173-.711-2.755-1.801-5.109-3.27-7.061-1.467-1.953-3.33-3.467-5.585-4.541-2.255-1.075-4.912-1.613-7.97-1.613zm-32.923.682c-2.815 1.695-5.388 3.02-7.72 3.973-2.33.954-4.465 1.68-6.402 2.18-1.938.5-3.693.81-5.268.93a58.478 58.478 0 0 1-3.784.18l-.484.002-1.09 4.269h8.81L631.833 911h13.396l14.803-69.568zm32.787 4.859c.938 0 1.748.363 2.429 1.09.681.726 1.241 1.717 1.68 2.974.44 1.256.765 2.702.977 4.336a40.29 40.29 0 0 1 .317 5.177c0 3.603-.204 7.213-.613 10.83a100.2 100.2 0 0 1-1.725 10.445c-.742 3.345-1.635 6.463-2.68 9.354-1.044 2.891-2.194 5.404-3.45 7.538-1.257 2.135-2.604 3.815-4.042 5.04-1.438 1.227-2.914 1.84-4.428 1.84-1.271 0-2.3-.394-3.087-1.18-.788-.788-1.4-1.832-1.84-3.134-.439-1.302-.741-2.785-.908-4.45a51.557 51.557 0 0 1-.25-5.131c0-3.452.235-6.948.704-10.49a98.953 98.953 0 0 1 1.908-10.286c.802-3.315 1.763-6.425 2.883-9.331 1.12-2.907 2.331-5.442 3.633-7.607 1.302-2.164 2.672-3.875 4.11-5.131 1.438-1.256 2.898-1.884 4.382-1.884z"
      />
    )}
  />
);

export default Number10Svg;
