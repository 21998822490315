import React from "react";
import NumberSvg from "./number-svg";

type IconProps = {
  className?: string;
  color?: string;
};
const Number5Svg = ({ color, className }: IconProps) => (
  <NumberSvg
    {...{ color, className }}
    getPath={(id) => (
      <path
        transform="translate(-96 -604)"
        fill={`url(#${id})`}
        fillRule="evenodd"
        d="M161 604c35.899 0 65 29.101 65 65s-29.101 65-65 65-65-29.101-65-65 29.101-65 65-65zm23.455 30.34c-2.876.97-5.63 1.65-8.264 2.044-2.634.394-5.283.59-7.947.59a81.9 81.9 0 0 1-7.584-.363 274.604 274.604 0 0 1-6.364-.668l-1.628-.194-7.22 35.192 1.271.909c2.453-1.454 4.663-2.52 6.63-3.202 1.968-.681 3.815-1.022 5.54-1.022 3.24 0 5.752 1.15 7.538 3.452 1.787 2.3 2.68 5.63 2.68 9.99 0 2.513-.303 4.813-.909 6.902-.605 2.09-1.445 3.898-2.52 5.427-1.074 1.529-2.369 2.717-3.882 3.564-1.514.848-3.18 1.272-4.995 1.272-2.18 0-3.913-.613-5.2-1.84-1.287-1.225-1.93-2.943-1.93-5.153 0-2.422.757-4.39 2.27-5.904 1.514-1.513 3.543-2.421 6.086-2.724-.636-3.149-2.846-4.723-6.63-4.723-1.786 0-3.414.31-4.882.931a10.814 10.814 0 0 0-3.792 2.657c-1.06 1.15-1.884 2.527-2.474 4.132-.59 1.604-.886 3.375-.886 5.313 0 4.329 1.446 7.667 4.337 10.013 2.89 2.346 7.091 3.52 12.601 3.52 3.936 0 7.53-.614 10.785-1.84 3.254-1.226 6.032-2.96 8.333-5.2 2.3-2.24 4.087-4.919 5.358-8.037 1.272-3.118 1.907-6.585 1.907-10.399 0-2.937-.386-5.593-1.158-7.97-.772-2.376-1.869-4.39-3.292-6.039a14.413 14.413 0 0 0-5.177-3.814c-2.028-.894-4.298-1.34-6.811-1.34-3.562 0-7.234.886-11.018 2.659l-.516.247 3.723-16.847c.909.121 1.998.31 3.27.568a217.1 217.1 0 0 0 4.019.772c1.407.257 2.823.484 4.245.68 1.423.198 2.755.296 3.997.296 1.967 0 3.632-.28 4.995-.84 1.362-.56 2.46-1.332 3.292-2.316a9.224 9.224 0 0 0 1.816-3.429c.379-1.301.568-2.694.568-4.177a26.287 26.287 0 0 0-.182-3.088z"
      />
    )}
  />
);

export default Number5Svg;
