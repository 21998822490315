import React from "react";
import styled from "styled-components";
import { RadialChart } from "react-vis";

import ChartLegend from "./chart-legend";

const mediaTypes = {
  Movies: {
    singular: "Movie",
    plural: "Movies",
  },
  "TV Series": {
    singular: "TV Series",
    plural: "TV Series",
  },
};

type ChartContainerProps = {
  size?: number;
};
const ChartContainer = styled.div<ChartContainerProps>`
  margin: 0 auto;
  position: relative;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

type InnerTextSvgProps = {
  outerRadius?: number;
  innerRadius?: number;
  count?: number;
};

const InnerTextSvg = styled.div<InnerTextSvgProps>`
  background: ${(props) => props.theme.Color.SecondaryBgColor};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: ${(props) => props.innerRadius}px;
  height: ${(props) => props.innerRadius}px;

  & > div {
    ${(props) => props.theme.FontHeaderBold()}
    color: ${(props) => props.theme.Color.SearchColor};
    font-size: 40px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.4;
  }
`;

const InnerText = ({ count, outerRadius, innerRadius }: InnerTextSvgProps) => (
  <InnerTextSvg {...{ outerRadius, innerRadius }}>
    <div>{count}</div>
  </InnerTextSvg>
);

type ComponentProps = {
  userProfile?: any;
  DEFAULT_COLOR?: string;
  VARIABLE_COLOR?: string;
  PIE_CHART_SIZE?: number;
  PIE_CHART_WIDTH?: number;
};
const RadialChartComponent = ({
  userProfile,
  DEFAULT_COLOR,
  VARIABLE_COLOR,
  PIE_CHART_SIZE,
  PIE_CHART_WIDTH,
}: ComponentProps) => {
  const { movie: movieCount, tv: tvCount } = userProfile.contentTypeCounts;
  const totalViewCount = movieCount + tvCount;

  const mediaTypeData = [
    {
      title: "Movies",
      angle: movieCount / totalViewCount,
      count: movieCount,
      color: DEFAULT_COLOR,
      singular: mediaTypes["Movies"].singular,
      plural: mediaTypes["Movies"].plural,
    },
    {
      title: "TV Series",
      angle: tvCount / totalViewCount,
      count: tvCount,
      color: VARIABLE_COLOR,
      singular: mediaTypes["TV Series"].singular,
      plural: mediaTypes["TV Series"].plural,
    },
  ];

  let totalWatchCount = 0;
  for (const m of mediaTypeData) {
    totalWatchCount += m.count;
  }

  return (
    <div>
      <ChartContainer size={PIE_CHART_SIZE}>
        <RadialChart
          data={mediaTypeData}
          width={PIE_CHART_SIZE}
          height={PIE_CHART_SIZE}
          radius={PIE_CHART_SIZE / 2}
          innerRadius={PIE_CHART_SIZE / 2 - PIE_CHART_WIDTH}
          getLabel={(d) => d.title}
          colorType="literal"
          style={{
            margin: "0 auto",
          }}
          labelsStyle={{
            margin: "0 auto",
          }}
        />
        <InnerText
          count={totalWatchCount}
          outerRadius={PIE_CHART_SIZE}
          innerRadius={PIE_CHART_SIZE / 1.2 - PIE_CHART_WIDTH}
        />
      </ChartContainer>

      <ChartLegend items={mediaTypeData} />
    </div>
  );
};

export default RadialChartComponent;
