import React from "react";
import NumberSvg from "./number-svg";

type IconProps = {
  className?: string;
  color?: string;
};
const Number2Svg = ({ className, color }: IconProps) => (
  <NumberSvg
    {...{ color, className }}
    getPath={(id) => (
      <path
        transform="translate(-47)"
        fill={`url(#${id})`}
        fillRule="evenodd"
        d="M112 130c-35.899 0-65-29.101-65-65S76.101 0 112 0s65 29.101 65 65-29.101 65-65 65zm18.849-46.512l-.272-.181c-1.602 1.783-3.121 3.015-4.557 3.695-1.436.68-2.94 1.02-4.511 1.02-1.058 0-2.169-.106-3.333-.317a142.618 142.618 0 0 1-3.695-.726 72.495 72.495 0 0 0-4.058-.725 29.685 29.685 0 0 0-4.42-.318c-.938 0-1.86.046-2.766.136a18.63 18.63 0 0 0-2.902.544c.937-1.722 2.138-3.37 3.604-4.942a59.372 59.372 0 0 1 4.852-4.624c1.768-1.512 3.627-3 5.577-4.466a242.897 242.897 0 0 0 5.758-4.466 96.263 96.263 0 0 0 5.418-4.67 38.1 38.1 0 0 0 4.557-5.033 24.018 24.018 0 0 0 3.128-5.532c.771-1.934 1.156-4.02 1.156-6.257 0-2.66-.445-5.04-1.337-7.14-.892-2.102-2.139-3.877-3.74-5.328-1.603-1.451-3.507-2.562-5.714-3.333-2.206-.77-4.624-1.156-7.254-1.156-3.174 0-5.977.476-8.41 1.428-2.434.952-4.467 2.192-6.099 3.718-1.632 1.527-2.871 3.234-3.718 5.124-.846 1.889-1.27 3.755-1.27 5.6 0 1.54.182 2.908.545 4.102.362 1.194.846 2.2 1.45 3.016.605.816 1.315 1.435 2.132 1.858a5.538 5.538 0 0 0 2.584.635c1.149 0 2.343-.28 3.582-.839a13.04 13.04 0 0 0 3.446-2.29v-.362c-1.21-.725-2.252-1.882-3.129-3.469-.876-1.586-1.315-3.513-1.315-5.78 0-1.028.182-2.003.544-2.925a7.51 7.51 0 0 1 1.497-2.403 6.837 6.837 0 0 1 2.312-1.61c.907-.392 1.904-.589 2.993-.589 1.209 0 2.251.287 3.128.862.877.574 1.602 1.367 2.176 2.38.575 1.013.99 2.222 1.247 3.627.257 1.406.386 2.94.386 4.602 0 2.51-.439 4.882-1.315 7.119a30.872 30.872 0 0 1-3.491 6.46 50.53 50.53 0 0 1-4.965 6.008 252.502 252.502 0 0 1-5.668 5.713 666.66 666.66 0 0 0-5.667 5.6 58.315 58.315 0 0 0-4.988 5.645c-1.466 1.904-2.645 3.869-3.536 5.894a16.044 16.044 0 0 0-1.383 6.302c0 1.42.181 2.872.544 4.353h.363c1.904-.998 3.695-1.693 5.372-2.086a21.532 21.532 0 0 1 4.92-.59c1.723 0 3.408.197 5.055.59 1.648.393 3.257.816 4.83 1.27 1.57.453 3.112.876 4.624 1.27 1.511.392 3.007.589 4.488.589 1.633 0 3.197-.272 4.693-.816a12.74 12.74 0 0 0 3.968-2.29 11.042 11.042 0 0 0 2.743-3.537c.68-1.375 1.02-2.909 1.02-4.602 0-1.662-.393-3.582-1.18-5.758z"
      />
    )}
  />
);

export default Number2Svg;
