import React from "react";
import styled, { css } from "styled-components";

import useTheme from "../../hooks/use-theme";

import Number1Svg from "../assets/numbers/number-1-svg";
import Number2Svg from "../assets/numbers/number-2-svg";
import Number3Svg from "../assets/numbers/number-3-svg";
import Number4Svg from "../assets/numbers/number-4-svg";
import Number5Svg from "../assets/numbers/number-5-svg";
import Number6Svg from "../assets/numbers/number-6-svg";
import Number7Svg from "../assets/numbers/number-7-svg";
import Number8Svg from "../assets/numbers/number-8-svg";
import Number9Svg from "../assets/numbers/number-9-svg";
import Number10Svg from "../assets/numbers/number-10-svg";

const Ul = styled.ul`
  ${(props) => {
    const { MediaQuery, MobileMaxWidth } = props.theme;

    return css`
      text-align: left;
      margin: 0;
      padding: 0;
      width: 100%;

      @media ${MediaQuery.BetweenWidths(600, MobileMaxWidth)} {
        margin: 0 auto;
        display: inline-block;
      }
    `;
  }}
`;

const Li = styled.li`
  box-sizing: border-box;
  color: ${(props) => props.theme.Color.SecondaryColorOnLight};
  border-bottom: rgba(0, 0, 0, 0.1) solid 1px;
  padding: 0 ${(props) => props.theme.Padding * 2}px;
  width: 100%;
  height: 50px;

  display: flex;
  align-items: center;
`;

const ICON_SIZE = 20;
const IconContainer = styled.div`
  & > svg {
    margin-right: ${(props) => props.theme.Padding}px;
    width: ${ICON_SIZE}px;
    height: ${ICON_SIZE}px;
    flex-shrink: 0;
  }
`;

const numberIcons = [
  Number1Svg,
  Number2Svg,
  Number3Svg,
  Number4Svg,
  Number5Svg,
  Number6Svg,
  Number7Svg,
  Number8Svg,
  Number9Svg,
  Number10Svg,
];

type TopTraitsProps = {
  topTraits?: any;
  title?: string;
  iconColor?: string;
  useDefaultGradient?: boolean;
};
const TopTraitsList = ({ topTraits, useDefaultGradient }: TopTraitsProps) => {
  if (topTraits.length > numberIcons.length) {
    throw new Error(
      "Limit to 10 traits. Create a new number icon to increase the trait length."
    );
  }

  const theme = useTheme();
  const colors = theme.ChartColorArray;

  return (
    <Ul>
      {topTraits.map((pair, index) => {
        const Icon = numberIcons[index];
        const color = !useDefaultGradient && colors[index];

        return (
          <Li key={index}>
            <IconContainer>
              <Icon color={color} />
            </IconContainer>
            {pair.name}
          </Li>
        );
      })}
    </Ul>
  );
};

export default TopTraitsList;
