import React from "react";
import NumberSvg from "./number-svg";

type IconProps = {
  className?: string;
  color?: string;
};
const Number6Svg = ({ className, color }: IconProps) => (
  <NumberSvg
    {...{ color, className }}
    getPath={(id) => (
      <path
        transform="translate(-271 -597)"
        fill={`url(#${id})`}
        fillRule="evenodd"
        d="M336 597c35.899 0 65 29.101 65 65s-29.101 65-65 65-65-29.101-65-65 29.101-65 65-65zm9.74 30.34c-3.028 0-5.927.538-8.697 1.613-2.77 1.075-5.358 2.596-7.765 4.564-2.406 1.967-4.594 4.336-6.561 7.106-1.968 2.77-3.648 5.843-5.041 9.219-1.393 3.375-2.475 7.008-3.247 10.898-.772 3.89-1.158 7.954-1.158 12.193 0 4.359.379 8.09 1.135 11.193.757 3.103 1.915 5.639 3.474 7.606 1.56 1.968 3.535 3.414 5.926 4.337 2.392.923 5.207 1.385 8.447 1.385 2.21 0 4.351-.34 6.425-1.022a23.94 23.94 0 0 0 5.835-2.838 26.73 26.73 0 0 0 4.973-4.291 27.712 27.712 0 0 0 3.86-5.45 28.686 28.686 0 0 0 2.497-6.289c.59-2.21.886-4.465.886-6.766 0-2.512-.348-4.76-1.045-6.743-.696-1.983-1.665-3.67-2.906-5.063a12.174 12.174 0 0 0-4.496-3.179c-1.755-.727-3.693-1.09-5.812-1.09-3.451 0-6.448.727-8.991 2.18-2.433 1.39-4.214 3.444-5.345 6.164l-.15.375-.318.09c.666-4.42 1.635-8.506 2.907-12.26 1.271-3.754 2.724-6.993 4.359-9.718 1.635-2.724 3.39-4.859 5.267-6.403 1.877-1.544 3.77-2.316 5.677-2.316.817 0 1.529.144 2.134.432a4.469 4.469 0 0 1 1.521 1.158c.409.484.712 1.044.908 1.68.197.636.296 1.287.296 1.953 0 1.06-.258 2.096-.772 3.11a5.12 5.12 0 0 1-2.27 2.293c.362.878.983 1.59 1.861 2.135a5.324 5.324 0 0 0 2.86.817c.97 0 1.87-.22 2.703-.658a6.685 6.685 0 0 0 2.157-1.817c.605-.772 1.082-1.68 1.43-2.724.348-1.045.522-2.157.522-3.338 0-1.574-.318-3.012-.953-4.314a9.478 9.478 0 0 0-2.725-3.338c-1.18-.923-2.603-1.634-4.268-2.134-1.665-.5-3.512-.75-5.54-.75zm-8.31 33.968c1.09 0 2.088.174 2.997.522a5.683 5.683 0 0 1 2.338 1.68c.651.772 1.166 1.779 1.544 3.02.378 1.241.568 2.755.568 4.54 0 2.725-.31 5.253-.931 7.584-.62 2.331-1.438 4.352-2.452 6.063-1.015 1.71-2.157 3.05-3.429 4.018-1.271.97-2.558 1.453-3.86 1.453-1.12 0-2.134-.242-3.042-.726-.908-.484-1.68-1.272-2.316-2.361-.636-1.09-1.128-2.49-1.476-4.2-.348-1.711-.522-3.793-.522-6.245 0-2.33.197-4.442.59-6.334.394-1.893 1.014-3.505 1.862-4.837.848-1.332 1.938-2.361 3.27-3.088 1.332-.726 2.951-1.09 4.858-1.09z"
      />
    )}
  />
);

export default Number6Svg;
