const lightTheme = (parentTheme) => ({
  ...parentTheme,
  Color: {
    ...parentTheme.Color,
    TransparentLight: parentTheme.Color.SecondaryButtonColorOnLight,
    PrimaryColor: parentTheme.Color.SecondaryColorOnLight,
    GradientColorBottom: parentTheme.Color.SecondaryButtonColorOnLight,
    PosterDefaultColor: parentTheme.Color.SecondaryButtonColorOnLight,
    BorderColor: parentTheme.Color.SecondaryColorOnLight,
    SecondaryColor: parentTheme.Color.SecondaryColorOnLight,
    LinkHoverTextColor: parentTheme.Color.DarkText,
  },
});

export default lightTheme;
