import React from "react";
import Svg from "../svg";

type IconProps = {
  className?: string;
  preserveAspectRatio?: boolean;
};

const ArrowDown = ({ className, preserveAspectRatio }: IconProps) => (
  <Svg
    width="10"
    height="6"
    className={className}
    preserveAspectRatio={preserveAspectRatio ? "xMidYMid meet" : null}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.93.216c.047.066.07.14.07.215a.378.378 0 0 1-.07.216L5.449 5.784A.544.544 0 0 1 5 6a.543.543 0 0 1-.448-.216L.069.647a.37.37 0 0 1 0-.431A.545.545 0 0 1 .518 0h8.964c.185 0 .356.082.449.216z"
    />
  </Svg>
);

export default ArrowDown;
