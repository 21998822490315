import React from "react";
import NumberSvg from "./number-svg";

type IconProps = {
  className?: string;
  color?: string;
};
const Number9Svg = ({ className, color }: IconProps) => (
  <NumberSvg
    {...{ color, className }}
    getPath={(id) => (
      <path
        transform="translate(-405 -841)"
        fill={`url(#${id})`}
        fillRule="evenodd"
        d="M470 841c35.899 0 65 29.101 65 65s-29.101 65-65 65-65-29.101-65-65 29.101-65 65-65zm6.744 30.205c-2.33 0-4.586.31-6.766.93-2.18.621-4.23 1.499-6.153 2.634a27.008 27.008 0 0 0-5.29 4.087 26.958 26.958 0 0 0-4.132 5.245 25.909 25.909 0 0 0-2.68 6.108 23.645 23.645 0 0 0-.953 6.698c0 2.512.348 4.76 1.044 6.743.696 1.983 1.665 3.67 2.906 5.063a12.174 12.174 0 0 0 4.496 3.18c1.756.726 3.709 1.089 5.858 1.089 3.42 0 6.403-.727 8.946-2.18 2.432-1.39 4.214-3.444 5.344-6.164l.15-.375.318-.09c-.575 4.389-1.43 8.46-2.565 12.215-1.136 3.754-2.468 7.008-3.996 9.763-1.53 2.755-3.21 4.912-5.041 6.47-1.832 1.56-3.716 2.34-5.654 2.34-1.604 0-2.785-.462-3.542-1.386a4.841 4.841 0 0 1-1.135-3.156c0-1.029.265-2.035.795-3.02a5.653 5.653 0 0 1 2.248-2.293c-.303-.877-.893-1.596-1.771-2.157-.878-.56-1.877-.84-2.997-.84-.97 0-1.855.227-2.657.682a7.022 7.022 0 0 0-2.111 1.861c-.606.787-1.075 1.703-1.408 2.748a10.924 10.924 0 0 0-.5 3.337c0 1.605.318 3.05.954 4.337a9.642 9.642 0 0 0 2.702 3.315c1.165.923 2.58 1.635 4.246 2.134 1.665.5 3.527.75 5.585.75 2.906 0 5.692-.538 8.356-1.613 2.664-1.074 5.146-2.596 7.447-4.563 2.3-1.968 4.39-4.33 6.266-7.084 1.877-2.755 3.482-5.82 4.814-9.196 1.332-3.375 2.361-7.016 3.088-10.921.726-3.905 1.09-7.977 1.09-12.215 0-8.144-1.446-14.26-4.337-18.346-2.891-4.087-7.213-6.13-12.965-6.13zm-2.815 6.13c1.544 0 2.77.409 3.678 1.226.908.817 1.59 1.832 2.044 3.043.454 1.21.749 2.49.885 3.837s.204 2.565.204 3.655c0 2.513-.204 4.814-.613 6.903-.408 2.088-1.074 3.89-1.998 5.403-.923 1.514-2.126 2.695-3.61 3.542-1.483.848-3.284 1.272-5.404 1.272-.968 0-1.846-.182-2.633-.545-.787-.363-1.476-.931-2.066-1.703-.59-.772-1.045-1.763-1.363-2.974-.318-1.211-.477-2.664-.477-4.36 0-2.33.288-4.647.863-6.947.575-2.301 1.37-4.367 2.384-6.199 1.014-1.832 2.218-3.315 3.61-4.45 1.393-1.135 2.891-1.703 4.496-1.703z"
      />
    )}
  />
);

export default Number9Svg;
