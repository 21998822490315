import React from "react";
import NumberSvg from "./number-svg";

type IconProps = {
  className?: string;
  color?: string;
};
const Number7Svg = ({ className, color }: IconProps) => (
  <NumberSvg
    {...{ color, className }}
    getPath={(id) => (
      <path
        transform="translate(-446 -604)"
        fill={`url(#${id})`}
        fillRule="evenodd"
        d="M511 604c35.899 0 65 29.101 65 65s-29.101 65-65 65-65-29.101-65-65 29.101-65 65-65zm-3.497 29.66c-1.877 0-3.602.257-5.176.772-1.575.514-2.93 1.24-4.065 2.18a9.658 9.658 0 0 0-2.633 3.405c-.62 1.332-.931 2.816-.931 4.45 0 .908.098 1.847.295 2.816.164.807.407 1.604.728 2.39l.203.47.272.091c1.453-1.725 2.884-2.959 4.292-3.7 1.407-.742 3.034-1.113 4.881-1.113 1.12 0 2.187.106 3.202.318 1.014.212 2.005.446 2.974.704.969.257 1.945.492 2.929.703.984.212 1.99.318 3.02.318a12.5 12.5 0 0 0 3.042-.363c.969-.242 2.028-.53 3.179-.863 0 .878-.394 1.953-1.18 3.224-.788 1.272-1.817 2.71-3.089 4.314a160.417 160.417 0 0 1-4.382 5.268 204.454 204.454 0 0 0-5.018 6.04 178.673 178.673 0 0 0-5.017 6.584 60.04 60.04 0 0 0-4.383 6.97 43.894 43.894 0 0 0-3.087 7.22c-.788 2.438-1.181 4.852-1.181 7.244 0 1.755.22 3.33.658 4.722.44 1.393 1.06 2.573 1.862 3.542a7.99 7.99 0 0 0 2.93 2.225c1.15.515 2.436.772 3.859.772 1.03 0 2.096-.136 3.201-.408 1.105-.273 2.218-.712 3.338-1.317A30.154 30.154 0 0 1 511 697.96a27.624 27.624 0 0 1-.454-4.95c0-2.603.393-5.312 1.18-8.128a61.407 61.407 0 0 1 3.111-8.537 100.605 100.605 0 0 1 4.382-8.628 211.276 211.276 0 0 1 5.018-8.378c1.71-2.71 3.383-5.305 5.018-7.788a244.983 244.983 0 0 0 4.382-6.88c1.287-2.103 2.323-3.973 3.11-5.608.735-1.525 1.127-2.787 1.176-3.786l.005-.21-.181-.363c-2.453.666-4.738 1.158-6.857 1.476-2.12.318-4.012.477-5.677.477-1.816 0-3.458-.16-4.927-.477a98.327 98.327 0 0 1-4.268-1.022 147.905 147.905 0 0 0-4.11-1.022c-1.362-.317-2.83-.476-4.405-.476z"
      />
    )}
  />
);

export default Number7Svg;
