import React from "react";
import styled, { css } from "styled-components";
import { readableColor } from "polished";

import Svg from "../svg";

type SvgStyledProps = {
  $bgColor?: string;
};
const SvgStyled = styled(Svg)<SvgStyledProps>`
  ${(props) =>
    props.$bgColor &&
    css`
      border-radius: 50%;
      border: ${props.$bgColor} 1px solid;
    `}
`;

type IconProps = {
  className?: string;
  color?: string;
  getPath?: any;
};
const NumberSvg = ({ color, getPath }: IconProps) => {
  // If we use the default gradient, it is okay that they use the same id
  const id = `number-icon-${color || "gradient"}`;
  const path = getPath(id);
  const contrastColor = !!color && readableColor(color);

  return (
    <SvgStyled
      width="130"
      height="130"
      preserveAspectRatio="xMidYMid meet"
      $bgColor={contrastColor}
    >
      <defs>
        <linearGradient x1="100%" y1="100%" y2="0%" id={id}>
          <stop stopColor={color || `#3F51B5`} offset="0%" />
          <stop stopColor={color || `#8034B2`} offset="100%" />
        </linearGradient>
      </defs>

      {/* Not every color is readable on all background colors - use a contrasting color for readability - css doesn't work here as the css background color is slightly larger than svg circle created in the path */}
      {!!contrastColor && (
        <circle id="Oval" fill={contrastColor} cx="65" cy="65" r="60"></circle>
      )}
      {path}
    </SvgStyled>
  );
};

export default NumberSvg;
