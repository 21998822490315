import React, { useState } from "react";
import styled, { ThemeProvider, css } from "styled-components";

import Page from "./page";
import Loading from "./controls/loading";
import lightTheme from "../theme/light-theme";
import Button, { ButtonVariant } from "./controls/button";
import Stack, { StackDirection, StackAlignment } from "@/components/stack";
import Tooltip, { TooltipPosition } from "@/components/controls/tooltip";

import ArrowDown from "@/components/assets/icons/arrow-down";

import useTheme from "@/hooks/use-theme";
import { useResponsive } from "./responsive-provider";

const CARD_SPACING = (props) => props.theme.Padding * 3;

const Cards = styled.div`
  ${(props) => css`
    display: flex;
    margin-bottom: ${CARD_SPACING}px;

    @media ${props.theme.MediaQuery.TabletL.MAX} {
      display: block;
    }
  `}
`;

const CardDescription = styled(Page.Subtitle)`
  /* Because this is centered, it can have side padding */
  margin: 0 ${(props) => props.theme.Padding}px
    ${(props) => props.theme.Padding * 2}px;
`;

type SiloPaddingProps = {
  $isTouchDevice?: boolean;
};
const CardSiloPadding = styled.div<SiloPaddingProps>`
  ${(props) => css`
    box-sizing: border-box;

    /* Touch device silos do not have arrows and will not need this padding */
    ${!props.$isTouchDevice &&
    css`
      padding: 0 ${props.theme.Padding * 5}px;
    `}
  `}
`;

const SiloPadding = ({ children }) => {
  const { isTouchDevice } = useResponsive();

  return (
    <CardSiloPadding $isTouchDevice={isTouchDevice}>{children}</CardSiloPadding>
  );
};

type ContainerProps = {
  $width?: number;
};
const CardContainer = styled.div<ContainerProps>`
  ${(props) => css`
    background-color: ${props.theme.Color.CardBgColor};
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &:not(:last-of-type) {
      margin-right: ${CARD_SPACING}px;

      @media ${props.theme.MediaQuery.TabletL.MAX} {
        margin-bottom: ${CARD_SPACING}px;
      }
    }

    ${props.$width && `width: ${props.$width}%;`}

    @media ${props.theme.MediaQuery.TabletL.MAX} {
      width: 100%;
    }
  `}
`;

const CardTitle = styled.div`
  ${(props) => css`
    border-bottom: rgba(0, 0, 0, 0.1) solid 1px;
    margin: 0;
    padding: ${props.theme.Padding * 2}px ${props.theme.Padding * 2}px
      ${props.theme.Padding * 1.5}px;

    @media ${props.theme.MediaQuery.TabletL.MAX} {
      text-align: center;
    }
  `}
`;

const LoadingContainerStyled = styled(Loading.Container)`
  height: 300px;
`;

type ContentProps = {
  $paddingStyle: string;
};
const CardContent = styled.div<ContentProps>`
  box-sizing: border-box;

  width: 100%;
  height: 100%;
  min-height: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  ${(props) => css`
    padding: ${props.$paddingStyle};
  `}

  /* Needed for when there is less than 4 people on the Cast & Crew card */
  text-align: center;

  & > div {
    width: 100%;
  }
`;

type ArrowType = {
  $isOpen?: boolean;
};
const ArrowContainer = styled.div<ArrowType>`
  position: relative;
  width: 16px;

  ${(props) =>
    props.$isOpen &&
    css`
      & > svg {
        transform: scaleY(-1);
      }
    `}
`;

export enum PaddingType {
  VERTICAL,
  HORIZONTAL,
  BOTH,
  NONE,
}

type CardProps = {
  title?: any;
  width?: number;
  children?: React.ReactNode;
  padding?: PaddingType;
  isCollapsable?: boolean;
};
const Card = ({
  title,
  width,
  padding = PaddingType.NONE,
  children,
  isCollapsable,
}: CardProps) => {
  const [showContent, setShowContent] = useState(true);
  const theme = useTheme();

  let childData: any = (
    <LoadingContainerStyled>
      <ThemeProvider theme={lightTheme}>
        <Loading />
      </ThemeProvider>
    </LoadingContainerStyled>
  );

  if (children) {
    childData = children;
  }

  let content = childData;

  const cardSizePadding = theme.Padding * 2;
  if (title) {
    let paddingStyle;
    switch (padding) {
      case PaddingType.BOTH:
        paddingStyle = `${cardSizePadding}px ${cardSizePadding}px`;
        break;
      case PaddingType.VERTICAL:
        paddingStyle = `${cardSizePadding}px 0`;
        break;
      case PaddingType.HORIZONTAL:
        paddingStyle = `0 ${cardSizePadding}px`;
        break;
      default:
        paddingStyle = 0;
    }
    content = (
      <>
        <CardTitle>
          <Stack
            direction={StackDirection.ROW}
            verticalAlignment={StackAlignment.CENTER}
            horizontalAlignment={StackAlignment.SPACE_BETWEEN}
          >
            <Page.H2>{title}</Page.H2>
            {isCollapsable && (
              <Tooltip
                text={showContent ? "Hide" : "Show"}
                position={TooltipPosition.BottomCenter}
              >
                <div>
                  <Button
                    onClick={() => setShowContent(!showContent)}
                    variant={ButtonVariant.GrowableIcon}
                  >
                    <ArrowContainer $isOpen={showContent}>
                      <ArrowDown preserveAspectRatio />
                    </ArrowContainer>
                  </Button>
                </div>
              </Tooltip>
            )}
          </Stack>
        </CardTitle>
        {showContent && (
          <CardContent $paddingStyle={paddingStyle}>{childData}</CardContent>
        )}
      </>
    );
  }

  return <CardContainer $width={width}>{content}</CardContainer>;
};

export default Card;
Card.Cards = Cards;
Card.Description = CardDescription;
Card.SiloPadding = SiloPadding;
