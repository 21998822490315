import React from "react";
import styled from "styled-components";
import Pluralize from "../controls/pluralize";

const GraphContent = styled.div`
  margin-top: ${(props) => props.theme.Padding}px;
`;

const Legend = styled.div`
  display: flex;
  justify-content: center;
`;

const LegendItem = styled.p`
  margin: ${(props) => props.theme.Padding}px
    ${(props) => props.theme.Padding}px 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const LEGEND_DOT_SIZE = 16;
const LegendDot = styled.span`
  background-color: ${(props) => props.color};
  border-radius: 50%;
  display: inline-block;
  margin-right: ${(props) => props.theme.Padding / 2}px;
  width: ${LEGEND_DOT_SIZE}px;
  height: ${LEGEND_DOT_SIZE}px;
  flex-shrink: 0;
`;

type ChartLegendProps = {
  items?: any;
};
const ChartLegend = ({ items }: ChartLegendProps) => {
  return (
    <GraphContent>
      <Legend>
        {/* reversing here because the radial draws clockwise = right to left*/}
        {items
          .slice()
          .reverse()
          .map((d, i) => (
            <LegendItem key={i}>
              <LegendDot color={d.color} />

              {d.count ? (
                <Pluralize
                  amount={d.count}
                  singular={d.singular}
                  plural={d.plural}
                />
              ) : (
                d.title
              )}
            </LegendItem>
          ))}
      </Legend>
    </GraphContent>
  );
};

export default ChartLegend;
