import React from "react";
import NumberSvg from "./number-svg";

type IconProps = {
  className?: string;
  color?: string;
};
const Number4Svg = ({ className, color }: IconProps) => (
  <NumberSvg
    {...{ color, className }}
    getPath={(id) => (
      <path
        transform="translate(-96 -430)"
        fill={`url(#${id})`}
        fillRule="evenodd"
        d="M161 430c35.899 0 65 29.101 65 65s-29.101 65-65 65-65-29.101-65-65 29.101-65 65-65zm.93 31.022c-.727 0-1.506.045-2.339.136-.832.091-1.597.242-2.293.454.242.939.424 1.892.545 2.861.121.969.182 1.938.182 2.906 0 3.058-.576 6.169-1.726 9.332-1.15 3.164-2.672 6.35-4.564 9.559a97.47 97.47 0 0 1-6.493 9.604 275.216 275.216 0 0 1-6.266 7.906l-1.295 1.562v.454l1.953 3.633h.363c1.695-.847 3.398-1.385 5.108-1.612 1.71-.227 3.353-.34 4.927-.34 2.18 0 4.269.28 6.267.84l3.956 1.11 1.947.547L155.255 530h12.942l4.041-19.163a14.993 14.993 0 0 0 3.973-1.34c1.226-.62 2.286-1.37 3.18-2.247a9.814 9.814 0 0 0 2.088-2.975c.5-1.105.75-2.278.75-3.52 0-.817-.122-1.649-.364-2.497a10.9 10.9 0 0 0-.923-2.213l-.212-.375-.273-.182c-.968 1.12-1.945 2.036-2.929 2.748a13.913 13.913 0 0 1-2.6 1.502l-.374.155 4.586-21.615h-5.948l-7.538 21.706c-1.635-.303-3.293-.606-4.973-.908-1.68-.303-3.247-.454-4.7-.454-1.483 0-2.974.174-4.473.522-1.332.31-2.478.649-3.44 1.018l-.351.14-.091-.273a82.059 82.059 0 0 0 4.927-4.223c1.74-1.604 3.466-3.3 5.176-5.086a95.75 95.75 0 0 0 4.927-5.563 62.544 62.544 0 0 0 4.178-5.699c1.211-1.876 2.172-3.7 2.884-5.471.711-1.771 1.067-3.414 1.067-4.927 0-1.12-.19-2.173-.568-3.156a6.993 6.993 0 0 0-1.703-2.566c-.756-.727-1.68-1.294-2.77-1.703-1.09-.409-2.36-.613-3.814-.613z"
      />
    )}
  />
);

export default Number4Svg;
